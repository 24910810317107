import React from "react";
import { Link, navigate } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
//import Layout from "../components/layout";
import axios from "axios";
import { graphql } from 'gatsby';
import SweetAlert from "sweetalert-react";
import Dotimg from "../images/Rectangle.png";
import HeaderLight from "./../components/headerLight";
import Footer from "./../components/footer";
import { Radio } from "antd";
import "antd/dist/antd.css";

require("sweetalert/dist/sweetalert.css");
require("../css/payment.css");

const RadioGroup = Radio.Group;

var cartData = [];
var len, amountPayable, myCartData, totalQuantity;

class PaymentCheckOutOne extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    // window.localStorage.setItem("amountPayable",this.props.location.state.amountPayable)
    this.state = {
      showAlert: false,
      displayName: "none",
      displayEmail: "none",
      displayEmailInvalid: "none",
      displayPhone: "none",
      displayPhoneInvalid: "none",
      displayPincode: "none",
      displayPincodeInvalid: "none",
      displayHouse: "none",
      displayArea: "none",
      displayLandmark: "none",
      displayTown: "none",
      displayState: "none",
      displayEmail: "none",
      displayOrg: "none",
      displayQty: "none",
      cartLength: 0,
      name: "",
      phone: "",
      email: "",
      pincode: "",
      house: "",
      area: "",
      landmark: "",
      town: "",
      state: "",


      selectedDeliveryOption: "Standard delivery",
      selectedAddressOption: "Home",

      //anil code //
      // pay_full_name:"",
      // pay_phone:0,
      // pay_email:"",
      // pay_pincode:0,
      // pay_building:"",
      // pay_town:"",
      // pay_state:"",
      // pay_landmark:"",
      // pay_area:"",

    };
    this.handleDeliveryChange = this.handleDeliveryChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
  }

  componentDidMount() {
    //anil code//
    var email = "";
    if (window.sessionStorage.getItem("registeredemail") == null) {
      email = window.sessionStorage.getItem("email");
    }
    else {
      email = window.sessionStorage.getItem("registeredemail");
      this.setState({
        email: email
      })
    }


    // var info = this.props.data.allMysqlLeads.edges;

    // var testdata=info.filter(ele=>{
    //   if( ele.node.email==email)
    //   {
    //      this.setState({
    //        name:ele.node.full_name,
    //        phone:ele.node.phone,
    //        email:ele.node.email,
    //        pincode:ele.node.pincode,
    //        house:ele.node.building,
    //        landmark:ele.node.landmark,
    //        area:ele.node.area,
    //        state:ele.node.state,
    //        town:ele.node.town


    //      })
    //   }

    // })
    var dataobject = {
      email: email

    }
    axios.post("https://dashboard.nativecircle.in/wp-json/api/login", dataobject)
      .then(response => {
        console.log(response.data.message)
        if (response.data.status == 200) {
          var payDetails = response.data.message[0]
          this.setState({
            name: payDetails.full_name,
            phone: payDetails.phone,
            email: payDetails.email,
            pincode: payDetails.pincode,
            house: payDetails.building,
            landmark: payDetails.landmark,
            area: payDetails.area,
            state: payDetails.state,
            town: payDetails.town


          })

        }

      })
      .catch(err => {
        console.log(err);
      });






    // Razorpay script

    const script1 = document.createElement("script");
    script1.type = "text/javascript";
    script1.async = true;
    script1.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script1);



    if (window.localStorage.getItem("cartDetails") == null) {
      window.localStorage.setItem("cartDetails", "[]");
      // cartData.length = 0;
    } else {
      // cartData = this.props.data.allWordpressWpNativeproduct.edges;
      amountPayable = window.localStorage.getItem("amountPayable");
      var parseMyCartData = JSON.parse(window.localStorage.getItem("myCartData"));
      myCartData = parseMyCartData
      totalQuantity = window.localStorage.getItem("totalQuantity");

      var initialData = window.localStorage.getItem("cartDetails");
      var parse = JSON.parse(initialData);
      len = parse.length;
      this.setState({ cartLength: len });

      // cartData.map((e, dataIndex) => {
      //   parse.map((product, index) => {
      //     if (product.id == e.node.id) {
      //       var cartItem = true;
      //       cartData[dataIndex].node.cartItem = cartItem;
      //     }
      //   });
      // });
      // this.setState({ catFilter: cartData });
    }

    // var productArray = this.props.location.state.products;
    // console.log("productArray", productArray);
    // var newProductArray = [];

    // productArray.map(e => {
    //   var eObj = {
    //     title: e.node.acf.product_title,
    //     quantity: e.node.quantity,
    //     price: e.node.acf.price
    //   };

    //   newProductArray.push(eObj);
    // });

    // console.log("newProductArray", newProductArray);
    // console.log("totalPrice", totalPrice);
  }

  handleDeliveryChange(e) {
    // console.log("e", e, e.target.value)
    this.setState({ selectedDeliveryOption: e.target.value });
  }

  handleAddressChange(e) {
    // console.log("e", e, e.target.value)
    this.setState({ selectedAddressOption: e.target.value });
  }

  proceedtoPayment() {
    // var name = document.getElementById("name").value;
    // var number = document.getElementById("number").value;
    var email = document.getElementById("email").value;
    console.log(email);
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // var phone = document.getElementById("number").value;
    var phoneLength = document.getElementById("number").value.length;
    var pincodeLength = document.getElementById("pincode").value.length;
    // var pincode = document.getElementById("pincode").value;
    // var house = document.getElementById("building").value;
    // var area = document.getElementById("area").value;
    // var landmark = document.getElementById("landmark").value;
    // var town = document.getElementById("town").value;
    // var state = document.getElementById("state").value;
    console.log(this.state.email);
    if (this.state.name == "") {
      // console.log("name is empty");
      this.validateName();
    } else if (this.state.phone == "" || phoneLength < 10) {
      this.validatePhone();
    } else if (this.state.email == "" || !pattern.test(email)) {
      this.validateEmail();
    } else if (this.state.pincode == "" || pincodeLength < 6 || pincodeLength > 6) {
      this.validatePincode();
    } else if (this.state.house == "") {
      this.validateHouse();
    } else if (this.state.area == "") {
      this.validateArea();
    } else if (this.state.landmark == "") {
      this.validateLandmark();
    } else if (this.state.town == "") {
      this.validateTown();
    } else if (this.state.state == "") {
      this.validateState();
    } else {
      // var _this = this;
      console.log("inside else")
      var amount = amountPayable * 100;
      // var amount = 1 * 100;
      var options = {
        key: "rzp_live_Gy5B0Tnnajnki4",
        //  key: "rzp_test_jm8ks5RCghSwec",
        // "amount": this.props.location.state.amountPayable*100,

        amount: amount,
        name: "NATIVECIRCLE",
        description: "Strengthening the roots of Native India",

        handler: function (response) {
          // console.log("rzp response", response);

          // console.log("razorpay_payment_id", response.razorpay_payment_id);
          // console.log("get delivery_type", this.state.selectedDeliveryOption)
          // console.log("get address_type", this.state.selectedAddressOption)

          //window.location.href = "/paymentconfirmation";
          // var _this = this;
          var productArray = myCartData;
          var totalPrice = amountPayable;

          console.log("productArray", productArray);

          var newProductArray = [];
          var stringify;
          productArray.map(e => {
            var eObj = {
              title: e.node.acf.product_title,
              quantity: e.node.quantity,
              price: e.node.acf.price,
              gst: e.node.acf.gst_percentage,
              deliveryCharge: e.node.acf.delivery_charges,
              imageUrl: e.node.acf.image.source_url,
              woocommerceProductId: e.node.acf.woocommerce_product_id
            };

            newProductArray.push(eObj);
            stringify = JSON.stringify(newProductArray);
          });
          var totalPrice = amountPayable;
          console.log(totalPrice);
          var totalProductsLength = myCartData.length;
          // console.log("htmlData", htmlData);
          var bodyFormData = new FormData();
          bodyFormData.set("paymentId", response.razorpay_payment_id);
          bodyFormData.set("deliveryType", this.state.selectedDeliveryOption);
          bodyFormData.set("fullName", this.state.name);
          bodyFormData.set("phone", this.state.phone);
          bodyFormData.set("email", this.state.email);
          bodyFormData.set("pincode", this.state.pincode);
          bodyFormData.set("building", this.state.house);
          bodyFormData.set("area", this.state.area);
          bodyFormData.set("landmark", this.state.landmark);
          bodyFormData.set("town", this.state.town);
          bodyFormData.set("state", this.state.state);
          bodyFormData.set("addressType", this.state.selectedAddressOption);
          bodyFormData.set("check", stringify);
          bodyFormData.set("totalprice", totalPrice);
          bodyFormData.set("totalproducts", totalProductsLength);
          bodyFormData.set("productsArr", stringify);
          bodyFormData.set(
            "totalQuantity",
            totalQuantity
          );
          bodyFormData.set("formID", 223);

          axios
            .post(
              "https://dashboard.nativecircle.in/index.php/wp-json/contact-form-7/v1/contact-forms/223/feedback",
              bodyFormData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }
            )
            .then(response => {
              document.getElementById("form").reset();

              if (response.status == 200 || response.status == 201) {
                // _this.setState({
                //   showAlert: true
                // });
              }
              console.log(response, "response");

            })
            .catch(err => {
              console.log(err);
            });



          var props = this.props.location.state;
          var razorpay_payment_id = response.razorpay_payment_id;
          navigate("/paymentconfirmation", {
            state: { props, razorpay_payment_id, response }
          });
          window.localStorage.setItem("cartDetails", "[]");
        }.bind(this),

        theme: {
          color: "#F37254"
        }
      };

      var rzp = new window.Razorpay(options);
      rzp.open();
    }
  }

  validateName() {
    var name = document.getElementById("name").value.replace(/[^a-zA-Z\s]/g, "");
    console.log("name", name)
    if (name == "" || name == undefined) {
      this.setState({ displayName: "block", name: "" });
    } else {
      this.setState({ displayName: "none", name: name });
    }
  }

  validateEmail() {
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var email = document.getElementById("email").value;
    if (email == "" || email == undefined) {
      this.setState({ displayEmail: "block", email: "" });
    } else {
      this.setState({ displayEmail: "none", email: email });
      if (!pattern.test(email)) {
        this.setState({ displayEmailInvalid: "block" });
      } else {
        this.setState({ displayEmailInvalid: "none" });
      }
    }
  }

  // validatePhone() {
  //   // var phonePattern = /[0-9]{10}/;
  //   var phone = document.getElementById("number").value.replace(/[^\d]/,'');
  //   var phoneLength = document.getElementById("number").value.length;

  //   if (this.state.phone == undefined) {
  //     this.setState({ displayPhone: "block" });
  //   } else {
  //     this.setState({ displayPhone: "none", phone: phone });
  //     // console.log("phonePattern.test(phone)", phonePattern.test(phone));
  //     if (phoneLength < 10) {
  //       this.setState({ displayPhoneInvalid: "block" });
  //     } else {
  //       this.setState({ displayPhoneInvalid: "none" });
  //     }
  //   }
  // }
  validatePhone() {

    var phone = document.getElementById("number").value;
    var phonePattern = /^\d+$/.test(phone);
    // var phoneValue = document.getElementById("number").value.replace(/[^\d]/,'');

    if (phonePattern == true) {
      var phoneLength = document.getElementById("number").value.length;
      if (phone == "" || phone == undefined) {
        this.setState({ displayPhone: "block", phone: "" });
      } else {
        this.setState({ displayPhone: "none", phone: phone });
        // console.log("phonePattern.test(phone)", phonePattern.test(phone));
        if (phoneLength < 10) {
          this.setState({ displayPhoneInvalid: "block" });
        } else {
          this.setState({ displayPhoneInvalid: "none" });
        }
      }
    }
    else {
      this.setState({ displayPhone: "block", phone: "" });
    }

  }


  validatePincode() {
    //  var pincode = e.target.value;
    var pincode = document.getElementById("pincode").value.replace(/[^\d]/, '');
    var pincodeLength = document.getElementById("pincode").value.length;
    console.log("pincode", pincode)
    if (pincode == "") {
      this.setState({ displayPincode: "block", pincode: "" });
    } else {
      this.setState({ displayPincode: "none", pincode: pincode });
      if (pincodeLength < 6) {
        this.setState({ displayPincodeInvalid: "block" });
      } else {
        this.setState({ displayPincodeInvalid: "none" });
      }
    }
  }

  validateHouse() {
    var house = document.getElementById("building").value;
    if (house == "" || house == undefined) {
      this.setState({ displayHouse: "block", house: "" });
    } else {
      this.setState({ displayHouse: "none", house: house });
    }
  }

  validateArea() {
    var area = document.getElementById("area").value;
    if (area == "" || area == undefined) {
      this.setState({ displayArea: "block", area: "" });
    } else {
      this.setState({ displayArea: "none", area: area });
    }
  }

  validateLandmark() {
    var landmark = document.getElementById("landmark").value;
    if (landmark == "" || landmark == undefined) {
      this.setState({ displayLandmark: "block", landmark: "" });
    } else {
      this.setState({ displayLandmark: "none", landmark: landmark });
    }
  }

  validateTown() {
    var town = document.getElementById("town").value;
    if (town == "" || town == undefined) {
      this.setState({ displayTown: "block", town: "" });
    } else {
      this.setState({ displayTown: "none", town: town });
    }
  }

  validateState() {
    var state = document.getElementById("state").value;
    if (state == "" || state == undefined) {
      this.setState({ displayState: "block", state: "" });
    } else {
      this.setState({ displayState: "none", state: state });
    }
  }

  render() {
    var today = new Date();
    return (
      <div>
        <HeaderLight data={this.state.cartLength} pathname="/payment-checkout" />
        <div id="circle-rectang" className="container1 NC-vision">
          <div className="row mar-tpr-tp">
            <div className="col-sm-12 col-md-12 col-xs-12 padTop15 flt-lft">
              <div className="d-flex justify-content-center bd-highlight mb-3">
                <div className="p-2 bd-highlight  payTabs payTabActive  underline-fro-txt">
                  {" "}
                  Delivery{" "}
                  <span className="circle-rectang">
                    {" "}
                    <img src={Dotimg} />{" "}
                  </span>
                </div>
                <div className="p-2 bd-highlight">
                  Payment{" "}
                  <span className="circle-rectang">
                    {" "}
                    <img src={Dotimg} />
                  </span>
                </div>
                <div className="p-2 bd-highlight">Confirmation</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-xs-12 pad0">
              <form
                action="javascript:void(0)"
                // onSubmit={this.postForm.bind(this)}
                id="form"
              >
                <div className="row form-group">
                  <div className="row">
                    <div className="col-md-4 col-xs-12 col-sm-4 padTop15">
                      <input
                        id="radio-1"
                        className="radio-custom"
                        name="radio-group"
                        type="radio"
                        value="Standard delivery"
                        checked={
                          this.state.selectedDeliveryOption ===
                          "Standard delivery"
                        }
                        onChange={this.handleDeliveryChange}
                      />
                      <label
                        for="radio-1"
                        className="radio-custom-label deliveryType"
                      >
                        Standard delivery
                      </label>

                      <p className="deliveryTypeDesc">3-5 business days</p>
                    </div>

                    <div className="col-md-4 col-xs-12 col-sm-4 padTop15">
                      <input
                        id="radio-2"
                        className="radio-custom"
                        name="radio-group"
                        type="radio"
                        value="Next day delivery"
                        checked={
                          this.state.selectedDeliveryOption ===
                          "Next day delivery"
                        }
                        onChange={this.handleDeliveryChange}
                      />
                      <label
                        for="radio-2"
                        className="radio-custom-label deliveryType"
                      >
                        Next day delivery
                      </label>

                      <p className="deliveryTypeDesc">
                        Order before 8 PM for delivery tomorrow
                      </p>
                    </div>
                  </div>

                  <br />
                  <div className="col-md-12 col-xs-12 col-sm-12 padTop15 formHeading">
                    DELIVERY ADDRESS
                  </div>

                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="name" className="NC-formlabel">
                      Full name *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="text"
                      id="name"
                      value={this.state.name}
                      //required
                      onChange={() => this.validateName()}
                    //  onChange={()=>this.setState({
                    //   pay_full_name:document.getElementById("name").value
                    // })}
                    />
                    <p
                      style={{
                        display: this.state.displayName,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                  </div>
                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="number" className="NC-formlabel">
                      Mobile number *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="text"
                      id="number"
                      // maxlength={10}
                      value={this.state.phone}
                      maxlength="10"
                      size="10"
                      pattern='\d{10}'
                      // pattern="[0-9\/]*"
                      //  required
                      onChange={() => this.validatePhone()}
                    // onChange={()=>this.setState({
                    //   pay_phone:document.getElementById("number").value
                    // })}
                    />
                    <p
                      style={{
                        display: this.state.displayPhone,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                    <p
                      style={{
                        display: this.state.displayPhoneInvalid,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      Please enter 10 digit mobile number
                    </p>
                  </div>
                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="number" className="NC-formlabel">
                      Email *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="email"
                      id="email"
                      value={this.state.email}


                    // pattern="[0-9\/]*"
                    //  required
                    // onChange={() => this.validateEmail()}
                    // onChange={()=>this.setState({
                    //   pay_email:document.getElementById("email").value
                    // })}
                    />
                    <p
                      style={{
                        display: this.state.displayEmail,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                    <p
                      style={{
                        display: this.state.displayEmailInvalid,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      The entered email id is not valid
                    </p>
                  </div>
                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="pincode" className="NC-formlabel">
                      Pincode *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="text"
                      maxlength="6"
                      size="6"
                      pattern='\d{6}'
                      id="pincode"
                      value={this.state.pincode}
                      // required
                      onChange={() => this.validatePincode()}
                    // onChange={()=>this.setState({
                    //   pay_pincode:document.getElementById("pincode").value
                    // })}
                    />
                    <p
                      style={{
                        display: this.state.displayPincode,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                    <p
                      style={{
                        display: this.state.displayPincodeInvalid,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      Please enter 6 digit pincode
                    </p>
                  </div>
                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="building" className="NC-formlabel">
                      Flat , House no., Building *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="text"
                      id="building"
                      value={this.state.house}
                      //  required
                      onChange={() => this.validateHouse()}
                    //  onChange={()=>this.setState({
                    //   pay_building:document.getElementById("building").value
                    // })}
                    />
                    <p
                      style={{
                        display: this.state.displayHouse,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                  </div>
                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="area" className="NC-formlabel">
                      Area, Colony, Street *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="text"
                      id="area"
                      value={this.state.area}
                      //  required
                      onChange={() => this.validateArea()}
                    //  onChange={()=>this.setState({
                    //   pay_area:document.getElementById("area").value
                    // })}
                    />
                    <p
                      style={{
                        display: this.state.displayArea,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                  </div>
                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="landmark" className="NC-formlabel">
                      Landmark *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="text"
                      id="landmark"
                      value={this.state.landmark}
                      //  required
                      onChange={() => this.validateLandmark()}
                    // onChange={()=>this.setState({
                    //   pay_landmark:document.getElementById("landmark").value
                    // })}
                    />
                    <p
                      style={{
                        display: this.state.displayLandmark,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                  </div>
                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="town" className="NC-formlabel">
                      Town, City *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="text"
                      id="town"
                      //  required
                      value={this.state.town}
                      onChange={() => this.validateTown()}
                    //  onChange={()=>this.setState({
                    //   pay_town:document.getElementById("town").value
                    // })}
                    />
                    <p
                      style={{
                        display: this.state.displayTown,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                  </div>
                  <div className="col-md-4 col-xs-12 col-sm-6 padTop15 mrgBtm10">
                    <label htmlFor="state" className="NC-formlabel">
                      State *
                    </label>
                    <input
                      className="form-control NC-fullWidth formcontrolPayColorChange"
                      type="text"
                      id="state"
                      value={this.state.state}
                      //  required
                      onChange={() => this.validateState()}
                    //  onChange={()=>this.setState({
                    //   pay_state:document.getElementById("state").value
                    // })}
                    />
                    <p
                      style={{
                        display: this.state.displayState,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                  </div>
                  <div className="col-md-12 col-xs-12 col-sm-12 padTop15 mrgBtm10">
                    <label htmlFor="addressType" className="NC-formlabel">
                      Address type
                    </label>
                    <br />

                    {/* <div className="col-md-4 col-xs-12 col-sm-4 padTop15"> */}
                    <input
                      id="addrType1"
                      className="radio-custom-label deliveryType"
                      name="addressType"
                      type="radio"
                      value="Home"
                      checked={this.state.selectedAddressOption === "Home"}
                      onChange={this.handleAddressChange}
                    />
                    <label for="addrType1" className="addressType mrgRt100">
                      Home
                    </label>
                    {/* </div> */}

                    {/* <div className="col-md-4 col-xs-12 col-sm-4 padTop15"> */}
                    <input
                      id="addrType2"
                      className="radio-custom-label deliveryType"
                      name="addressType"
                      type="radio"
                      value="Work"
                      checked={this.state.selectedAddressOption === "Work"}
                      onChange={this.handleAddressChange}
                    />
                    <label for="addrType2" className="addressType">
                      Work
                    </label>
                    {/* </div> */}
                  </div>

                  {/* <div className="col-md-6 col-xs-12 col-sm-6 padTop15 mrgBtm10"> */}
                  {/* <label htmlFor="cartDetails" className="NC-formlabel"></label>
                    <input
                      className="form-control NC-fullWidth"
                      type="text"
                      id="cartDetails"
                      hidden 
                    // {/* //  value={this.props.location.state}
                    //   //required
                    //   // onInput={() => this.validateName()} */}
                  {/* />  */}

                  {/* </div> */}

                  <div className="col-md-12 col-xs-12 col-sm-12 NC-formsubmit">
                    <button
                      className="proceedBtn"
                      id="rzp-button1"
                      htmlType="submit"
                      onClick={() => this.proceedtoPayment()}
                      href=""
                    >
                      Proceed{" "}
                      <img
                        className="iconCheckout"
                        src="https://res.cloudinary.com/djoztpm50/image/upload/v1551181856/Native%20Circle/path.svg"
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="NC-paddingTop-50">
          <Footer />
        </div>
      </div>
    );
  }
}
export default PaymentCheckOutOne
// export const pageQuery = graphql`
// query Paymentcheckoutquery {
//   allMysqlLeads {
//     edges {
//       node {
//         full_name
//         phone
//         email
//         pincode
//         building
//         area
//         landmark
//         town
//         state



//       }
//     }
//   }
// }
// `;